@import 'variables';

h1,
h2,
h3,
h4,
h5,
h6 {
  &.#{$prefix}title {
    color: $color-text-titles;
    font-style: italic;
  }
}

.#{$prefix}input {
  font-size: 120%;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  padding: 8px 15px 6px;
  border-bottom: $color-button-primary 3px solid;
  width: 100%;
  max-width: 100%;

  &.ng-invalid {
    border-bottom: #ff0000 3px solid;
  }
}

select {
  &.#{$prefix}input {
    color: #000;
  }
}

.#{$prefix}input-helper {
  font-size: 75%;
  color: $color-text-titles;
}

.#{$prefix}button {
  &-primary,
  &-danger,
  &-wpp {
    text-decoration: none;
    text-align: center;

    background-color: $cashless-button-color-primary;
    border-radius: 20px;
    border: none;
    padding: 12px 30px;
    box-sizing: border-box;
    color: #000;

    &:hover {
      text-decoration: none;
      background-color: $cashless-button-color-primary-active;
    }
  }

  &-danger {
    color: #ff0000;
    background-color: transparent;
    font-size: 0.8rem;

    &:hover {
      color: #f0024b;
      background-color: transparent;
    }
  }

  &-wpp {
    background-color: #3fce6c;
    color: white;

    &:hover {
      background-color: #04b33b;
    }
  }

  &-w100 {
    width: 100%;
  }
}

.#{$prefix}button-white {
  background-color: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 12px 30px;
  box-sizing: border-box;
  box-shadow: 2px 5px 20px #00000029;

  &:hover {
    background-color: $cashless-button-color-primary-active;
  }
}

.#{$prefix}button-big {
  border: none;
  width: 100px;
  height: 125px;
  background: #ffffff;
  box-shadow: 2px 5px 20px #00000029;
  border-radius: 20px;
  font-size: 12px;

  .#{$prefix}img {
    width: 45px;
    max-height: 50px;
    margin-bottom: 10px;
  }

  &:disabled {
    .#{$prefix}img {
      filter: grayscale(100%) opacity(50%);
    }
  }
}

.#{$prefix}hr {
  color: $color-text-titles;
}

.#{$prefix}container {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
}
.#{prefix}center-content {
  min-height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.#{prefix}alert {
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .#{$prefix}icon,
  .#{$prefix}text {
    color: #fff;
  }

  .#{$prefix}text {
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
  }

  p {
    margin: 0;
  }

  &.danger {
    background-color: #f0024b;
  }
}

@mixin host-default() {
  display: block;
  padding-top: 30px;
  padding-bottom: 30px;
}
