// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@6.5.95/css/materialdesignicons.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');

@import 'node_modules/bootstrap/scss/bootstrap-grid';
@import 'node_modules/bootstrap/scss/bootstrap-reboot';
@import 'node_modules/bootstrap/scss/bootstrap-utilities';

@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/structure.css';

@import 'theme/variables';
@import 'theme/cashless-theme';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  user-select: none;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: $color-text-primary;
}

body {
  background: #e2ecef;
}

input,
textarea {
  user-select: text;
}

input,
button,
textarea {
  color: $color-text-primary;
  font-weight: 400;

  &:disabled {
    color: lighten($color-text-primary, 50%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 160%;
}

h2 {
  font-size: 140%;
}

h3 {
  font-size: 120%;
}

.mat-dialog-container {
  position: relative !important;
}
.dialog-close-button {
  position: absolute !important;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  background-color: #000;
  color: #fff;
  border-radius: 100%;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  .close-icon {
    margin: 0;
    padding: 0;
  }
}
span {
  &.font-normal {
    font-style: normal;
  }
}
